<!-- 勋章报告 -->
<template>
  <div class="stage">
    <div class="ctx-title">
      <div class="title-ctx">勋章报告</div>
      <div class="title-fk"></div>
    </div>
    <img
      class="content-img"
      src="https://lesson.iapeap.com/images/EmotionalValue/jieduantupian.png"
      alt=""
    />
    <div class="content">
      <div class="ctx2">本阶段练习中你可能遇到了哪些阻碍</div>
      <div class="ctx3">· 事情太多</div>
      <div class="ctx3">· 没有耐心</div>
      <div class="ctx3">· 拖延</div>
      <div class="ctx2">但是这些方法帮助你克服了它们，坚持完成了练习</div>
      <div class="ctx3">· 每天固定一个练习时间</div>
      <div class="ctx3">· 完成练习给自己一点小奖励</div>
      <div class="ctx2">想要获得更好的练习体验，或许你可以做一点小调整：</div>
      <div class="ctx4">· 定好闹钟，多记录感受</div>
      <div class="ctx5">
        或许你暂时没有完成这个阶段的全部练习，但是在这个过程中收获的体验也很棒！
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.stage {
  min-height: 100vh;
  background: #f4fafe;
  padding: 36px 24px;
  box-sizing: border-box;
  .ctx-title {
    .title-ctx {
      z-index: 999;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #000025;
      line-height: 25px;
    }
    .title-fk {
      width: 35px;
      height: 5px;
      background: #629ee9;
      position: relative;
      bottom: 8px;
      z-index: 998;
      transform: skew(-25deg);
    }
  }
  .content-img {
    width: 56px;
    height: 50px;
    position: absolute;
    right: 40px;
    top: 20px;
  }
  .content {
    // height: 401px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 10px 20px;
    box-sizing: border-box;

    .ctx2 {
      height: 18px;
      font-size: 13px;
      font-weight: 500;
      color: #000025;
      margin-top: 10px;

      line-height: 18px;
    }
    .ctx3 {
      margin-top: 10px;
      height: 18px;
      font-size: 13px;
      font-weight: 300;
      color: #000025;
      line-height: 18px;
      padding-left: 13px;
    }
    .ctx4 {
      margin-top: 20px;
      height: 18px;
      font-size: 13px;
      font-weight: 300;
      color: #000025;
      line-height: 18px;
      padding-left: 13px;
    }
    .ctx5 {
      font-size: 14px;
      font-weight: 500;
      color: #5faefa;
      line-height: 22px;
      margin-top: 50px;
    }
  }
}
</style>
